<template>
	<div class="login-container">
		<el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="login-form" autocomplete="on"
			label-position="left">

			<div class="title-container">
				<h3 class="title">芝华仕后台</h3>
			</div>

			<el-form-item prop="account">
				<el-input ref="username" v-model="ruleForm.account" placeholder="请输入账号" name="username" type="text"
					tabindex="1" autocomplete="on" prefix-icon="el-icon-user-solid" />
			</el-form-item>

			<el-tooltip content="Caps lock is On" placement="right" manual>
				<el-form-item prop="password">
					<el-input show-password prefix-icon="el-icon-s-cooperation" ref="password"
						v-model="ruleForm.password" placeholder="请输入密码" name="password" tabindex="2" autocomplete="on"
						type="password" />
				</el-form-item>
			</el-tooltip>

			<el-button type="primary" style="width:100%;margin-bottom:30px;" @click="submitForm('ruleForm')">Login
			</el-button>

			<div v-if="false" style="position:relative">
				<div class="tips">
					<span>Username : admin</span>
					<span>Password : any</span>
				</div>
				<div class="tips">
					<span style="margin-right:18px;">Username : editor</span>
					<span>Password : any</span>
				</div>

				<el-button class="thirdparty-button" type="primary" @click="showDialog=true">
					Or connect with
				</el-button>
			</div>
		</el-form>

	</div>
</template>

<script>
	export default {
		name: "Login",
		data() {
			return {
				ruleForm: {
					account: '', // admin
					password: '', //123456rd
				},
				rules: {
					account: [{
						required: true,
						message: '账号不能为空',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '密码不能为空',
						trigger: 'blur'
					}],
				}
			}
		},
		methods: {
			submitForm(formName) {
				var _this = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//发起登录请求
						var obj = {
							account: _this.ruleForm.account,
							password: _this.ruleForm.password
						}
						_this.$ajax('login', 'post', obj).then(res => {
							console.log(res, '接口成功')
							//_this.$store.commit("set_token", res.data.token);
							sessionStorage.setItem("token", res.data.token);
							console.log(sessionStorage.getItem("token", res.data.token), "储存接口")
							//sessionStorage.setItem("token",res.data.jwt);
							//console.log(sessionStorage.getItem("token"), 'getItem')
							this.$ajax('getInfo', 'get').then(res => {
								console.log(res, '用户信息')
								if (res.data.name == '管理员') {
									sessionStorage.setItem("User", true);
									console.log(sessionStorage.getItem("User"), '用户');
								} else {
									sessionStorage.setItem("User", false);
									console.log(sessionStorage.getItem("User"), '用户');
								}
								_this.$router.push('/')
							})
						}).catch(err => {
							console.log(err, 'err')
						})
					} else {
						console.log('error!');
						return false;
					}
				})
			},
			resetForm(formName) {
				this.$refs[formName].resetFields()
			}
		}
	}
</script>

<style lang="scss">
	/* 修复input 背景不协调 和光标变色 */
	/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

	$bg:#283443;
	$light_gray:#fff;
	$cursor: #fff;

	@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
		.login-container .el-input input {
			color: $cursor;
		}
	}

	/* reset element-ui css */
	.login-container {
		.el-input {
			//display: inline-block;
			height: 47px;
			//width: 85%;

			input {
				background: transparent;
				border: 0px;
				-webkit-appearance: none;
				border-radius: 0px;
				padding: 12px 5px 12px 15px;
				color: $light_gray;
				height: 47px;
				caret-color: $cursor;
				text-indent: 20px;

				&:-webkit-autofill {
					box-shadow: 0 0 0px 1000px $bg inset !important;
					-webkit-text-fill-color: $cursor !important;
				}
			}
		}

		.el-form-item {
			border: 1px solid rgba(255, 255, 255, 0.1);
			background: rgba(0, 0, 0, 0.1);
			border-radius: 5px;
			color: #454545;
		}
	}
</style>

<style lang="scss" scoped>
	$bg:#2d3a4b;
	$dark_gray:#889aa4;
	$light_gray:#eee;

	.login-container {
		min-height: 100vh;
		width: 100%;
		background-color: $bg;
		overflow: hidden;

		.login-form {
			position: relative;
			width: 520px;
			max-width: 100%;
			padding: 160px 35px 0;
			margin: 0 auto;
			overflow: hidden;
		}

		.tips {
			font-size: 14px;
			color: #fff;
			margin-bottom: 10px;

			span {
				&:first-of-type {
					margin-right: 16px;
				}
			}
		}

		.title-container {
			position: relative;

			.title {
				font-size: 26px;
				color: $light_gray;
				margin: 0px auto 40px auto;
				text-align: center;
				font-weight: bold;
			}
		}

		.show-pwd {
			position: absolute;
			right: 10px;
			top: 7px;
			font-size: 16px;
			color: $dark_gray;
			cursor: pointer;
			user-select: none;
		}

		.thirdparty-button {
			position: absolute;
			right: 0;
			bottom: 6px;
		}

		@media only screen and (max-width: 470px) {
			.thirdparty-button {
				display: none;
			}
		}
	}
</style>
